// vim: set fdn=2 :
import * as Sentry from "@sentry/react";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import {
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  signInWithCustomToken,
} from "firebase/auth";

import Spinner from "../components/spinner";
import { request } from "../hooks/request";
import i18n from "../i18n";
import EVALI_CONSTANTS from "../constants";
import { testIfSessionStorageAvailable } from "../components/generated-item/utils";
import { useTranslation } from "react-i18next";

// Taken from
// https://timcchang.com/blog/react-user-authentication-with-firebase/
// https://gist.github.com/timc1/d559d0f8769b4badc0bfc22484fe97a3

AuthProvider.actions = {
  SET_USER: "SET_USER",
  TOGGLE_LOADING: "TOGGLE_LOADING",
  SET_ERROR_CODE: "SET_ERROR_CODE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  SIGNIN_FAILED: "SIGNIN_FAILED",
  SIGN_OUT: "SIGN_OUT",
  SET_TOTP_QR: "SET_TOTP_QR",
  GET_TOTP_QR_FAILED: "GET_TOTP_QR_FAILED",
  ENABLE_TOTP_FAILED: "ENABLE_TOTP_FAILED",
  VERIFY_TOTP_FAILED: "VERIFY_TOTP_FAILED",
};

const reducer = (state, action) => {
  switch (action.type) {
    case AuthProvider.actions.SET_USER:
      action.payload?.user_data?.organizations.forEach((org) => {
        if (Number.parseInt(Cookies.get("evali-org")) === org.id) {
          action.payload.user_data.org = org.id;
          action.payload.user_data.role = org.role;
        }
      });
      return {
        ...state,
        ...action.payload,
        is_initially_loading: false,
        is_loading: false,
      };
    case AuthProvider.actions.TOGGLE_LOADING:
      return {
        ...state,
        is_loading: action.payload.value,
      };
    case AuthProvider.actions.SET_ERROR_CODE:
      return {
        ...state,
        is_loading: false,
        error_code: action.payload.code,
      };
    case AuthProvider.actions.CLEAR_ERRORS:
      return {
        ...state,
        error_code: null,
      };
    case AuthProvider.actions.SIGNIN_FAILED:
      return {
        ...state,
        is_loading: false,
        error_code: "auth/session-failure",
      };
    case AuthProvider.actions.SIGN_OUT:
      return {
        ...state,
        user: null,
        user_data: null,
        is_loading: false,
      };
    case AuthProvider.actions.SET_TOTP_QR:
      return {
        ...state,
        is_loading: false,
        totp_qr: action.payload,
      };
    case AuthProvider.actions.GET_TOTP_QR_FAILED:
      return {
        ...state,
        is_loading: false,
        error_code: action.payload,
      };
    case AuthProvider.actions.ENABLE_TOTP_FAILED:
      return {
        ...state,
        is_loading: false,
        error_code: action.payload,
      };
    case AuthProvider.actions.VERIFY_TOTP_FAILED:
      return {
        ...state,
        error_code: action.payload,
        is_loading: false,
      };
    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
};

const AuthContext = createContext(undefined);

export function AuthProvider({ children }) {
  const history = useHistory();
  const { t: _t } = useTranslation();
  const [ml_user, setMlUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, {
    is_initially_loading: true,
    is_loading: false,
    user: null,
    user_data: null,
    error_code: null,
  });

  useEffect(() => {
    if (!testIfSessionStorageAvailable()) {
      const snackbar = document.getElementById("snackbar");
      snackbar.innerText = _t(
        "Your device or browser is blocking Session Storage. This may cause evali to not function properly.",
      );
      snackbar.className = "show";
    }
  }, []);

  useEffect(() => {
    // to prevent setState on unmounted component
    let is_cancelled = false;
    _setMlUser();
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (is_cancelled) {
        return;
      }
      if (user) {
        Sentry.setUser({ email: user.email });
        _getSession(user);
        user.getIdToken(true);
      } else {
        // User is signed out.
        dispatch({
          type: AuthProvider.actions.SET_USER,
          payload: { user: null },
        });
      }
    });
    return () => {
      is_cancelled = true;
      return unsubscribe;
    };
  }, []);

  const _setMlUser = (ml_user) => {
    if (ml_user) {
      sessionStorage.setItem("ml_user", JSON.stringify(ml_user));
      setMlUser(ml_user);
    } else {
      try {
        const _ml_user = JSON.parse(sessionStorage.getItem("ml_user"));
        if (_ml_user) {
          setMlUser(_ml_user);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const _getSession = (user) => {
    toggleLoading(true);
    return request(`${EVALI_BASE_URL}/api/user/session`)
      .then((user_data) => {
        dispatch({
          type: AuthProvider.actions.SET_USER,
          payload: { user, user_data },
        });
      })
      .catch(() => {
        const auth = getAuth();
        return signOut(auth).then(() => {
          dispatch({ type: AuthProvider.actions.SIGN_OUT });
          dispatch({ type: AuthProvider.actions.SIGNIN_FAILED });
        });
      });
  };

  const nsSignIn = (jwt) => {
    signout().then(() => {
      fetch(`${EVALI_BASE_URL}/api/user/ns-sign-in`, {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ jwt }),
      })
        .then((res) => res.json())
        .then(({ token, user_data, org_id }) => {
          const auth = getAuth();
          signInWithCustomToken(auth, token).then((user) => {
            Cookies.set("evali-org", org_id, {
              sameSite: "Lax",
              secure: true,
            });
            dispatch({
              type: AuthProvider.actions.SET_USER,
              payload: { user, user_data },
            });
            history.push(`${EVALI_CONSTANTS.ADMIN_START}`);
          });
        });
    });
  };

  const reloadSession = () => {
    return _getSession(state.user);
  };

  const clearErrors = () => {
    dispatch({ type: AuthProvider.actions.CLEAR_ERRORS });
  };

  const signup = (email, password, name, extra, to_route) => {
    toggleLoading(true);

    const loc = window.location;
    const continue_url = `${loc.protocol}//${loc.host}?ev=1`;
    const data = {
      name,
      email,
      password,
      ...extra,
      lang: i18n.language,
      url: continue_url,
    };
    return request(
      `${EVALI_BASE_URL}/api/user`,
      {
        method: "post",
        body: data,
      },
      false,
    )
      .then((user) => {
        toggleLoading(false);
        signin(user.email, user.pwd).then(() => {
          _getSession(user).then(() => {
            history.push(to_route);
          });
        });
      })
      .catch((json) => {
        dispatch({
          type: AuthProvider.actions.SET_ERROR_CODE,
          payload: {
            code: json.error,
          },
        });
      });
  };

  const signin = (email, password) => {
    toggleLoading(true);
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        toggleLoading(false);
        return user;
      })
      .catch((error) => {
        dispatch({
          type: AuthProvider.actions.SET_ERROR_CODE,
          payload: {
            code: error.code,
          },
        });
      });
  };

  const signout = () => {
    toggleLoading(true);
    // returning here to be able to react to promise from outside
    const auth = getAuth();
    return signOut(auth)
      .then(() => {
        dispatch({ type: AuthProvider.actions.SIGN_OUT });
      })
      .catch(() => {
        toggleLoading(false);
      });
  };

  const sendResetPasswordEmail = (email) => {
    toggleLoading(true);
    return fetch(`${EVALI_BASE_URL}/api/user/pwd_reset`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email, lang: i18n.language }),
    })
      .then((response) => response.json())
      .then((json) => {
        toggleLoading(false);
        return json;
      });
  };

  const changePassword = (current_pwd, new_pwd) => {
    toggleLoading(true);
    dispatch({
      type: AuthProvider.actions.SET_ERROR_CODE,
      payload: {
        code: null,
      },
    });
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, current_pwd);
    return reauthenticateWithCredential(user, credential)
      .then(() => {
        return updatePassword(user, new_pwd)
          .then(() => {
            toggleLoading(false);
            return;
          })
          .catch((error) => {
            toggleLoading(false);
            dispatch({
              type: AuthProvider.actions.SET_ERROR_CODE,
              payload: {
                code: error.code,
              },
            });
          });
      })
      .catch((error) => {
        toggleLoading(false);
        dispatch({
          type: AuthProvider.actions.SET_ERROR_CODE,
          payload: {
            code: error.code,
          },
        });
      });
  };

  const toggleLoading = (is_loading) => {
    dispatch({
      type: AuthProvider.actions.TOGGLE_LOADING,
      payload: { value: is_loading },
    });
  };

  const setupTotp = () => {
    toggleLoading(true);
    request(`${EVALI_BASE_URL}/api/user/setup-totp`)
      .then((result) => {
        dispatch({
          type: AuthProvider.actions.SET_TOTP_QR,
          payload: result.svg,
        });
      })
      .catch(({ message }) => {
        console.error(message);
        dispatch({
          type: AuthProvider.actions.GET_TOTP_QR_FAILED,
          payload: message,
        });
      });
  };

  const authenticateWIthTotp = (totpPassword) => {
    toggleLoading(true);
    request(`${EVALI_BASE_URL}/api/user/verify-totp`, {
      method: "post",
      body: { totpPassword },
    })
      .then(() => {
        reloadSession();
        toggleLoading(false);
      })
      .catch(({ message }) => {
        console.error(message);
        dispatch({
          type: AuthProvider.actions.VERIFY_TOTP_FAILED,
          payload: message,
        });
      });
  };

  const enableTotp = (totpPassword) => {
    toggleLoading(true);
    request(`${EVALI_BASE_URL}/api/user/enable-totp`, {
      method: "post",
      body: { totpPassword },
    })
      .then(() => {
        reloadSession();
        toggleLoading(false);
      })
      .catch(({ message }) => {
        dispatch({
          type: AuthProvider.actions.ENABLE_TOTP_FAILED,
          payload: message,
        });
      });
  };

  const isMfaVerified = () => Boolean(state.user_data?.mfa?.verified);

  const value = {
    user: state.user,
    user_data: state.user_data,
    ml_user,
    signup,
    signin,
    signout,
    sendResetPasswordEmail,
    changePassword,
    reloadSession,
    clearErrors,
    setupTotp,
    authenticateWIthTotp,
    enableTotp,
    isMfaVerified,
    nsSignIn,
    setMlUser: _setMlUser,
    totp_qr: state.totp_qr,
    is_loading: state.is_loading,
    error_code: state.error_code,
  };

  if (state.is_initially_loading) {
    return <Spinner height="26rem" message="App starting" />;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
